import React, {useEffect} from 'react';
import Navbar from '../Navbar';
import "./style.scss";
import gehrt from "../../imgs/gehrt.jpg";
import natours from '../../imgs/natours.jpg';
import bank from '../../imgs/bankist.jpg';
import expensify from '../../imgs/expensify.jpg';
import school from '../../imgs/school.jpeg';
import { hover } from '@testing-library/user-event/dist/hover';


const Portfolio = () => {

  useEffect(() => {
    const link = document.querySelectorAll('.link');
    const hoverReveal = document.querySelectorAll('.hover-reveal');
    const linkImages = document.querySelectorAll('.hidden-img');
    
    
    const mmStyle = {
      opacity: 1
    }

    link[0].addEventListener('mousemove', (e) => {
      hoverReveal[0].style.opacity = 1;
      hoverReveal[0].style.transform = `translate(-10%, -50%) rotate(5deg)`;
      linkImages[0].style.transform = `scale(1, 1)`;
      //hoverReveal[0].style.left = e.clientX + "px";
    })

    link[0].addEventListener('mouseleave', (e) => {
      hoverReveal[0].style.opacity = 0;
      hoverReveal[0].style.transform = `translate(-10%, -50%) rotate(-5deg)`;
      linkImages[0].style.transform = `scale(0.8, 0.8)`;
      //hoverReveal[0].style.left = e.clientX + "px";
    })


    link[1].addEventListener('mousemove', (e) => {
      hoverReveal[1].style.opacity = 1;
      hoverReveal[1].style.transform = `translate(-10%, -50%) rotate(5deg)`;
      linkImages[1].style.transform = `scale(1, 1)`;
      //hoverReveal[0].style.left = e.clientX + "px";
    })

    link[1].addEventListener('mouseleave', (e) => {
      hoverReveal[1].style.opacity = 0;
      hoverReveal[1].style.transform = `translate(-10%, -50%) rotate(-5deg)`;
      linkImages[1].style.transform = `scale(0.8, 0.8)`;
      //hoverReveal[0].style.left = e.clientX + "px";
    })

    link[2].addEventListener('mousemove', (e) => {
      hoverReveal[2].style.opacity = 1;
      hoverReveal[2].style.transform = `translate(-10%, -50%) rotate(5deg)`;
      linkImages[2].style.transform = `scale(1, 1)`;
      //hoverReveal[0].style.left = e.clientX + "px";
    })

    link[2].addEventListener('mouseleave', (e) => {
      hoverReveal[2].style.opacity = 0;
      hoverReveal[2].style.transform = `translate(-10%, -50%) rotate(-5deg)`;
      linkImages[2].style.transform = `scale(0.8, 0.8)`;
      //hoverReveal[0].style.left = e.clientX + "px";
    })


    link[3].addEventListener('mousemove', (e) => {
      hoverReveal[3].style.opacity = 1;
      hoverReveal[3].style.transform = `translate(-10%, -50%) rotate(5deg)`;
      linkImages[3].style.transform = `scale(1, 1)`;
      //hoverReveal[0].style.left = e.clientX + "px";
    })

    link[3].addEventListener('mouseleave', (e) => {
      hoverReveal[3].style.opacity = 0;
      hoverReveal[3].style.transform = `translate(-10%, -50%) rotate(-5deg)`;
      linkImages[3].style.transform = `scale(0.8, 0.8)`;
      //hoverReveal[0].style.left = e.clientX + "px";
    })




    // for(var i = 0; i < link.length; i++) {
    //   link[i].addEventListener('mousemove', (e) => {
    //     hoverReveal[i].style.opacity = 1;
    //     hoverReveal[i].style.transform = `translate(-100%, -50%) rotate(5deg)`;
    //     linkImages[i].style.transform = `scale(1, 1)`;

    //   })
    // }
  }, [])
  
    
 

  return (
    <div className="portfolio-container">
      <Navbar />
      <section className={"portfolio-section"}>
        <nav>
          <ul>
            <li>
              <div className="link">
                <span><a href="https://davidgehrt.dk" target="_blank">David Gehrt</a></span>
                <div className="hover-reveal img00" >
                  <img className="hidden-img" src={gehrt} alt="project 0" />
                </div>
              </div>
            </li>
            <li>
              <div className="link">
                <span><a href="https://natoursss-6aa9e9.netlify.app/" target="_blank">Natours</a></span>
                <div className="hover-reveal img01" >
                  <img className="hidden-img" src={natours} alt="project 1" />
                </div>
              </div>
            </li>
            <li>
              <div className="link">
                <span><a href="https://bankist-5faaf4.netlify.app" target="_blank">Bankist</a></span>
                <div className="hover-reveal img02">
                  <img className="hidden-img" src={bank} alt="project 2" />
                </div>
              </div>
            </li>
            <li>
              <div className="link">
                <span><a href="https://expensify-2f5b80.netlify.app" target="_blank">Expensify</a></span>
                <div className="hover-reveal img03" >
                  <img className="hidden-img" src={expensify} alt="project 3"/>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </section>
    </div>
    
  );
}

export default Portfolio;