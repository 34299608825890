import React from 'react';
import { Link } from 'react-router-dom';
import "./style.scss";
// import logo from "../../imgs/katlogo.png";


const Navbar = () => {
  return (
    <div className={"navbar-section"} data-scroll-section>
      <nav className="nav">
        <div className="logo">
          <Link to="/">[K]</Link>
          {/* <img src={logo} alt="my logo"/> */}
        </div>
        <ul className="nav">
          <Link to="/portfolio">
            <li>Portfolio</li>
          </Link>        
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;