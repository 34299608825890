import React from 'react';
import Navbar from '../Navbar';
import Foot from '../Foot';
import "./style.scss";
import me from '../../imgs/k.jpg';



const About = () => {
  return (
    <>
      <div className="about-container">
        <Navbar />
        <section className={"about-section"}>
          <h1>About</h1>
          <div className="about">
            <div className="about-pic">
              <img className="me" src={me}/>
            </div>
            <div className="description">
              <p>Hi! My name is Katlego Madonsela,</p>
              <p>
                I am a frontend developer based in Copenhagen Denmark, who has a passion 
                for crafting beautiful web experiences. I graduated from KEA (the Copenhagen
                school of design and technology) with a degree in multimedia design.
              </p>
              <p>
                When I am not working, I am probably reading, practicing piano, producing music, 
                playing basketball or working out.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Foot />
    </>
  );
}

export default About;