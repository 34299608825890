import React, {useEffect, useState, useRef} from 'react';
import "./style.scss";
import pig from "../../imgs/pig.jpeg";
import natours from "../../imgs/natours.jpg";
import bank from "../../imgs/bank.jpg";
import gehrt from "../../imgs/gehrt.jpg";

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import cn from 'classnames';
import useOnScreen from '../../hooks/useOnScreen';

const images = [
  {
    src: gehrt,
    title: "David Gehrt",
    subtitle: "Portfolio for danish scenographer",
    link: "https://davidgehrt.dk",
    key: "0"
  },
  {
    src: natours,
    title: "Natours",
    subtitle: "Tours in nature for the adventurous.",
    link: "https://natoursss-6aa9e9.netlify.app/", 
    key: "1"
  },
  {
    src: bank,
    title: "Bankist",
    subtitle: "A bank for minimalists.",
    link: "https://bankist-5faaf4.netlify.app", 
    key: "2"
  },
  {
    src: pig,
    title: "Expensify",
    subtitle: "A voice activated budget app.",
    link: "https://expensify-2f5b80.netlify.app",
    key: "3"
  }
];


function GalleryItem({src, title, subtitle, link, updateActiveImage, index}) {

  const ref = useRef(null);

  const onScreen = useOnScreen(ref, 0.5);
  useEffect(()=> {
    if(onScreen) {
      updateActiveImage(index);
    }
  }, [onScreen, index])

  return (
    <div className={cn("gallery-item-wrapper", {'is-reveal': onScreen})} ref={ref} >
      <div/>
      <div className="gallery-item">
        <div className="gallery-item-info">
          <h1 className="gallery-info-title">{title}</h1>
          <h6 className="gallery-info-subtitle">{subtitle}</h6>
          <a className="gallery-info-link" href={link} target="_blank">Visit Website</a>
        </div>

        <div className="gallery-item-image" style={{backgroundImage: `url(${src})`}}>
          
        </div>
      </div> 
      <div/> 
    </div>
  );
}


export default function Gallery() {

  const [activeImage, setActiveImage] = useState(1);
  const ref = useRef(null);

  const breakpointMobile = window.matchMedia('(max-width: 600px)');
  const breakpointDesktop = window.matchMedia('(min-width: 1024px)');

  useEffect(() => {

    if(breakpointDesktop.matches) {
      const sections = gsap.utils.toArray('.gallery-item-wrapper');
      gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: 'none',
        scrollTrigger: {
          start: "top top",
          trigger: ref.current,
          scroll: "#main-container",
          pin: true,
          scrub: 0.5,
          span: 1/(sections.length - 1),
          end: () => `+=${ref.current.offsetWidth}`,
        },
      });
      ScrollTrigger.refresh();
    }

    
  }, []);

  return (
    <section data-scroll-section className={"gallery-section gallery-wrap"} ref={ref}>
      <div className="gallery" >
        <div className="gallery-counter">
          <span>{activeImage}</span>
          <span className="divider"></span>
          <span>{images.length}</span>
        </div>

        {
          images.map((image, index) => (
            <GalleryItem
              //key={image.src}
              key={image.key}
              index={index}
              {...image}              
              updateActiveImage={index => setActiveImage(index + 1)}
            />
          ))
        }

      </div>
    </section>
  );
}
