import React from "react";
import './style.scss';

const Foot = () => {
  return (
    <div className="footer">
        <div className="footer__left">
          <p>Katlego Madonsela</p>
          <p>© 2022 All Rights Reserved.</p>
        </div>
        <div className="footer__right">
          <div className="footer__right--contact">
            <p className="strong">Contact Me</p>
            <p>+45 42425378</p>
            <p><a href="mailto:hello@katlego.dk">hello@katlego.dk</a></p>
          </div>
          <div className="footer_right--socials">
            <p className="strong">Socials</p>
            <p><a target="_blank" href="https://www.linkedin.com/in/katlego-madonsela-babb7755/">LinkedIn</a></p>
            <p><a target="_blank" href="https://www.facebook.com/katlego.madonsela/">Facebook</a></p>
            <p><a target="_blank" href="https://www.instagram.com/katlego_o/">Instagram</a></p>
          </div>
        </div>
      </div>
  );
}

export default Foot;