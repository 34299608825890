import React, { useEffect, useRef, useState } from "react";
import {BrowserRouter, Switch, Routes, Route} from 'react-router-dom';

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
//import CustomCursor from "../CustomCursor";
import Header from "../components/Header";
//import Featured from "../components/Featured";
//import About from "../components/About";
import Gallery from "../components/Gallery";
//import Portfolio from "../components/Portfolio";

import "../styles/home.scss";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/src/locomotive-scroll.scss';


const Home = () => {

  const [preloader, setPreloader] = useState(true);

  const [timer, setTimer] = useState(1);

  const id = useRef(null);

  const clear = () => {
    window.clearInterval(id.current);
    setPreloader(false);
  };

  useEffect(()=> {
    id.current = window.setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
  }, []);
  
  useEffect(() => {
    if (timer < 0) {
      clear();
    }
  }, [timer]);

  return (
    <>
      {/* <BrowserRouter>
        <Routes>
          <Route path="/" component={HomePage} exact/>
          <Route path="/portfolio" component={Portfolio} exact/>
          <Route path="/about" component={About} exact/>
        </Routes>
        <LocomotiveScrollProvider
          options={
            {
              smooth: false,
              multiplier: 1,
              class: 'is-reveal',
            }
          }
        >
          <div className="main-container" id="main-container" data-scroll-container>
            <Navbar />        
            <Header />
            <Featured />
            <Gallery />
            <Footer />
          </div>
        </LocomotiveScrollProvider>
      </BrowserRouter> */}

      {
        preloader ? (
          <div className="loader-wrapper absolute">
            <h1>Katlego Madonsela</h1>
            <h2>Frontend Web Developer</h2>
          </div> 
        ) : (
          <LocomotiveScrollProvider
            options={
              {
                smooth: false,
                multiplier: 1,
                class: 'is-reveal',
              }
            }
          >
            <div className="main-container" id="main-container" data-scroll-container>
              <Navbar />             
              <Header />
              <Gallery />
              <Footer />
            </div>
          </LocomotiveScrollProvider> 
        )
      }
      
      
    </>
  );
};

export default Home;

