import React from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import About from "../components/About";
import Portfolio from "../components/Portfolio";
import Home from "./Home";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} exact/>
          <Route path="/portfolio" element={<Portfolio/>} exact/>
          <Route path="/about" element={<About/>} exact/>
        </Routes>
      </BrowserRouter>      
    </>
  );
};

export default App;

