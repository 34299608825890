import React, {useEffect, useRef, useState} from 'react';
import "./style.scss";
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger); 

const Header = () => {
  const greetingRef = useRef(null);

  const breakpointDesktop = window.matchMedia('(min-width: 1024px)');
  const breakpointMobile = window.matchMedia('(max-width: 1023px)');

  useEffect(() => {
    const el = greetingRef.current;
    gsap.fromTo(el, {opacity: 0}, {opacity: 1, duration: 1, delay: 1})
  }, []);
  
  useEffect(() => {
    if(breakpointDesktop.matches) {
      gsap.to(".greeting", {
        //opacity: 0,
        y: -580,
      
        scrollTrigger: {
          trigger: ".subtext",
          scrub: 1,
          start: "top 80%+=50px",
        }
      })
  
      //const textEl = textRef1.current;
      gsap.to(".subtext h3", {
        backgroundPositionX: "0%",
        stagger: 1,
        scrollTrigger: {
          trigger: ".subtext",
          //scrub: 1,
          start: "top 80%+=50px",
          end: "bottom center",
        }
      })
  
      return () => {
        ScrollTrigger.getAll().forEach((instance) => {
          instance.kill();
        });
        // This in case a scroll animation is active while the route is updated
        gsap.killTweensOf(window);
      };
    }
  }, []);


  useEffect(() => {
    if(breakpointMobile.matches) {
      gsap.to(".subtext h3", {
        backgroundPositionX: "0%",
        stagger: 1,
        scrollTrigger: {
          trigger: ".subtext",
          //scrub: 1,
          start: "top 80%+=50px",
          end: "bottom center",
        }
      })
  
      return () => {
        ScrollTrigger.getAll().forEach((instance) => {
          instance.kill();
        });
        // This in case a scroll animation is active while the route is updated
        gsap.killTweensOf(window);
      };
    }
  }, []);

 

  // useEffect(() => {
  //   gsap.to(".greeting", {
  //     //opacity: 0,
  //     y: -580,
    
  //     scrollTrigger: {
  //       trigger: ".subtext",
  //       scrub: 1,
  //       start: "top 80%+=50px",
  //     }
  //   })

  //   //const textEl = textRef1.current;
  //   gsap.to(".subtext h3", {
  //     backgroundPositionX: "0%",
  //     stagger: 1,
  //     scrollTrigger: {
  //       trigger: ".subtext",
  //       //scrub: 1,
  //       start: "top 80%+=50px",
  //       end: "bottom center",
  //     }
  //   })

  //   return () => {
  //     ScrollTrigger.getAll().forEach((instance) => {
  //       instance.kill();
  //     });
  //     // This in case a scroll animation is active while the route is updated
  //     gsap.killTweensOf(window);
  //   };

  // }, []);

  return (
    <section className={"header-section"} data-scroll-section>
      <div ref={greetingRef} className="greeting">
        <h2>Hello, I'm <br/> Katlego.</h2> 
        <div className="scroll-down">Scroll Down</div>
      </div>
      <div className="subtext" data-color="LightSlateGray">
        <h3>I love to</h3>
        <h3>craft</h3>
        <h3>attractive</h3> 
        <h3>experiences</h3> 
        <h3>for the web.</h3>
      </div>   
    </section>
  );
}

export default Header;